@tailwind base;
@tailwind components;
@tailwind utilities;

/* Import Lato Semibold fonts directly - Google Fonts doens't provide these. */
@import url('../assets/fonts/lato.css');
@layer utilities {
  /* For white containers */
  .scrollbar::-webkit-scrollbar {
    @apply w-2 h-2;
  }

  .scrollbar::-webkit-scrollbar-track {
    @apply rounded-r-full bg-white;
  }

  .scrollbar::-webkit-scrollbar-thumb {
    @apply rounded-full bg-lightest-grey w-1;
  }

  .scrollbar::-webkit-scrollbar-thumb:hover {
    @apply bg-alice-blue;
  }
/* For gray containers */
.scrollbar-grey::-webkit-scrollbar {
  @apply w-2 h-2;
}

.scrollbar-grey::-webkit-scrollbar-track {
  @apply rounded-r-full bg-lightest-grey;
}

.scrollbar-grey::-webkit-scrollbar-thumb {
  @apply rounded-full bg-alice-blue w-1;
}

.scrollbar-grey::-webkit-scrollbar-thumb:hover {
  @apply bg-alice-blue;
}
/* Transform SVG colors from midnight to electric-indigo */
.svg-midnight-to-electric-indigo {
  /* This filter transforms #16325c (midnight) to #3049c5 (electric-indigo) */
  filter: brightness(1.3) saturate(1.5) hue-rotate(10deg);
}
}
html {
  -webkit-text-size-adjust: 100%; /* Prevent font scaling in landscape while allowing user zoom */
}

body {
  @apply h-screen;
}

#root {
  @apply h-full;
}

.trans-left-half {
  transform: translateX(-50%);
}

.trans-top-half {
  transform: translateY(-50%);
}

/* purgecss start ignore */
.admin-nav-link--active {
  background-color: rgba(0, 112, 210, 0.3);
}

.admin-nav-link--active:hover {
  background-color: rgba(0, 112, 210, 0.3);
}

.admin-nav-link--active svg {
  @apply text-white;
}

.react-datepicker__header {
  @apply text-center bg-link-water border-b border-geyser relative py-2;
}

.react-datepicker__header.react-datepicker-year-header,
.react-datepicker__current-month {
  @apply text-center font-bold text-sm text-midnight;
}

.react-datepicker__navigation {
  @apply leading-relaxed text-center cursor-pointer absolute top-0 mt-3 w-4 h-4 p-0 z-10 overflow-hidden;
  background: none;
  border: 0.45rem solid transparent;
  text-indent: -999em;
}

.react-datepicker__navigation:focus {
  @apply outline-none;
}

.react-datepicker__navigation--next {
  @apply mr-3 right-0;
  border-left-color: #e2e8f0;
}

.react-datepicker__navigation--previous {
  @apply ml-3 left-0;
  border-right-color: #e2e8f0;
}

.react-datepicker__month,
.react-datepicker__day {
  @apply m-2 text-center;
}

.react-datepicker__month .react-datepicker__month-text,
.react-datepicker__month .react-datepicker__quarter-text {
  @apply inline-block w-16 m-1 cursor-pointer rounded py-1;
}

.react-datepicker__week .react-datepicker__day {
  @apply inline-flex w-6 h-6 m-1 cursor-pointer rounded items-center justify-center;
}

.react-datepicker__day-name {
  @apply inline-block text-sm text-caded w-6 h-6 mt-1 mx-1;
}

.react-datepicker__day--outside-month {
  @apply text-caded;
}

.react-datepicker__month-text:hover:not(.react-datepicker__month--selected):not(.react-datepicker__month--disabled),
.react-datepicker__quarter-text:hover:not(.react-datepicker__month--selected):not(.react-datepicker__month--disabled),
.react-datepicker__day:hover:not(.react-datepicker__day--selected):not(.react-datepicker__day--disabled) {
  @apply bg-geyser;
}

.react-datepicker__month--selected,
.react-datepicker__day--selected {
  @apply bg-link text-white;
}

.react-datepicker__month-text.react-datepicker__month--disabled,
.react-datepicker__day.react-datepicker__day--disabled {
  @apply text-geyser cursor-not-allowed;
}
/* purgecss end ignore */

@keyframes placeHolderShimmer {
  0% {
    background-color: #f4f6f9;
  }
  50% {
    background-color: #d8dde6;
  }
}

.bg-animated {
  animation-duration: 1.25s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f4f6f9;
}

/* Remove arrows from int inputs */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.shadow-r {
  box-shadow: 0 -2px 3px 0 rgba(0, 0, 0, 0.1), 0 -2px 2px 0 rgba(0, 0, 0, 0.06);
}

.clamp-lines__button {
  @apply mt-2 border-t border-link-water w-full py-2 text-center text-link text-sm font-bold mx-auto outline-none;
}

.clamp-lines__button:focus {
  @apply outline-none;
}

.clamp-lines__button:hover {
  @apply bg-link-water;
}

table tbody tr:first-child td {
  @apply border-t border-geyser;
}

.aa-Item mark {
  @apply bg-link-lighter text-midnight font-medium not-italic;
}

:root {
  --aa-detached-media-query: (max-width: 0px);
  --aa-detached-modal-media-query: (min-width: 0px);
}

.aa-Autocomplete *,
.aa-DetachedFormContainer *,
.aa-Panel * {
  @apply box-border;
}

.aa-Autocomplete,
.aa-DetachedFormContainer,
.aa-Panel {
  @apply text-midnight font-sans text-base font-normal p-0 m-0 text-left;
}

.aa-Form {
  @apply h-11 items-center bg-link-water border-link-water border rounded flex m-0 relative w-full;
}

.aa-Form[focus-within],
.aa-Form:focus-within {
  @apply border-link border bg-white outline-none;
}

.aa-InputWrapperPrefix {
  @apply flex items-center flex-shrink-0 h-11;
  order: 1;
}

.aa-Label,
.aa-LoadingIndicator {
  @apply cursor-auto flex-shrink-0 h-full p-0 text-left;
}

.aa-Label svg,
.aa-LoadingIndicator svg {
  @apply text-kasmir h-auto w-5 stroke-1 max-h-5;
}

.aa-LoadingIndicator,
.aa-SubmitButton {
  @apply h-full pl-3 pr-2 w-11;
}

@media (hover: none) and (pointer: coarse) {
  .aa-LoadingIndicator,
  .aa-SubmitButton {
    @apply pl-1 w-10;
  }
}

.aa-SubmitButton:focus {
  @apply outline-none;
}

.aa-SubmitButton {
  @apply appearance-none bg-none border-0 m-0;
}

.aa-LoadingIndicator {
  @apply flex items-center justify-center;
}

.aa-LoadingIndicator[hidden] {
  @apply hidden;
}

.aa-InputWrapper {
  @apply relative w-full;
  order: 3;
}

.aa-Input {
  @apply appearance-none bg-transparent border-0 text-midnight h-11 p-0 w-full;
}

.aa-Input::placeholder {
  @apply text-kasmir opacity-100;
}

.aa-Input:focus {
  @apply border-0 shadow-none outline-none;
}

.aa-Input::-webkit-search-cancel-button,
.aa-Input::-webkit-search-decoration,
.aa-Input::-webkit-search-results-button,
.aa-Input::-webkit-search-results-decoration {
  @apply appearance-none;
}

.aa-InputWrapperSuffix {
  @apply flex items-center h-11;
  order: 4;
}

.aa-ClearButton {
  @apply flex items-center h-full bg-none border-0 text-kasmir cursor-pointer m-0 px-3;
}

@media (hover: none) and (pointer: coarse) {
  .aa-ClearButton {
    @apply px-2;
  }
}

.aa-ClearButton:focus {
  @apply outline-none;
}

.aa-ClearButton[hidden] {
  @apply hidden;
}

.aa-ClearButton svg {
  @apply stroke-1 w-5;
}

.aa-Panel {
  @apply bg-white rounded shadow mt-1 overflow-hidden absolute z-top;
  transition: opacity 0.2s ease-in, filter 0.2s ease-in;
}

@media screen and (prefers-reduced-motion) {
  .aa-Panel {
    transition: none;
  }
}

.aa-Panel button {
  @apply appearance-none bg-none border-0 m-0 p-0;
}

.aa-PanelLayout {
  @apply h-full m-0 overflow-y-auto p-0 relative text-left;
  max-height: 650px;
}

.aa-Panel--stalled .aa-Source {
  filter: grayscale(1);
  opacity: 0.8;
}

.aa-Panel--scrollable {
  @apply m-0 overflow-x-hidden overflow-y-auto p-2;
  max-height: 650px;
}

.aa-Source {
  @apply m-0 p-0 relative w-full;
}

.aa-Source:empty {
  @apply hidden;
}

.aa-SourceNoResults {
  @apply m-0 p-4 text-base;
}

.aa-List {
  @apply m-0;
  list-style: none;
}

.aa-List,
.aa-SourceHeader {
  @apply p-0 relative;
}

.aa-Item {
  @apply items-center grid cursor-pointer rounded-sm p-1;
  min-height: 40px;
}

.aa-Item[aria-selected="true"] {
  @apply bg-link-lighter;
}

.aa-Item[aria-selected="true"] .aa-ActiveOnly,
.aa-Item[aria-selected="true"] .aa-ItemActionButton {
  @apply visible;
}

.aa-ItemContent {
  @apply items-center grid cursor-pointer overflow-hidden;
  grid-gap: 8px;
  gap: 8px;
  grid-auto-flow: column;
  line-height: 1.25em;
}

.aa-ItemContent:empty {
  @apply hidden;
}

.aa-ItemLink,
.aa-ItemWrapper {
  @apply items-center text-midnight grid justify-between w-full;
  grid-gap: 4px;
  gap: 4px;
  grid-auto-flow: column;
}

.aa-ItemLink {
  @apply text-midnight no-underline;
}

.aa-ActiveOnly {
  @apply invisible;
}

.aa-DetachedContainer {
  @apply bg-white bottom-0 shadow flex flex-col left-0 m-0 overflow-hidden p-0 fixed right-0 top-0 z-top;
}

.aa-DetachedContainer:after {
  @apply h-8;
}

.aa-DetachedContainer .aa-SourceHeader {
  @apply my-2 ml-1;
}

.aa-DetachedContainer .aa-Panel {
  @apply bg-white rounded-none flex-grow shadow-none m-0 p-0 relative;
}

.aa-DetachedContainer .aa-PanelLayout {
  @apply bottom-0 shadow-none left-0 m-0 overflow-y-auto absolute right-0 top-0 w-full;
  max-height: none;
}

.aa-DetachedFormContainer {
  @apply border border-link-water flex flex-row justify-between m-0 p-2;
}

.aa-DetachedCancelButton {
  @apply bg-none border-0 rounded-sm text-kasmir cursor-pointer font-sans ml-2 px-2;
}

.aa-DetachedCancelButton:focus,
.aa-DetachedCancelButton:hover {
  @apply shadow;
}

.aa-DetachedContainer--modal {
  @apply rounded h-auto my-0 mx-auto absolute;
  bottom: inherit;
  max-width: 680px;
  position: absolute;
  top: 3%;
}

.aa-DetachedContainer--modal .aa-PanelLayout {
  @apply pb-2 static;
  max-height: 500px;
}

.aa-DetachedSearchButton {
  @apply m-0 relative w-full text-left px-1.5 h-11 text-base items-center flex bg-white border border-link-water rounded-sm text-kasmir cursor-pointer font-sans;
}

.aa-DetachedSearchButton:focus {
  @apply border border-link-water shadow outline-none;
}

.aa-DetachedSearchButtonIcon {
  @apply items-center text-kasmir cursor-auto flex h-full justify-center w-9;
}

.aa-Detached {
  @apply h-screen overflow-hidden;
}

.aa-DetachedOverlay {
  @apply bg-transparent h-screen left-0 m-0 p-0 fixed right-0 top-0 z-top-1;
}

@media (hover: none) and (pointer: coarse) {
  .aa-DesktopOnly {
    @apply hidden;
    display: none;
  }
}

@media (hover: hover) {
  .aa-TouchOnly {
    @apply hidden;
  }
}

.highlight em {
  @apply bg-yellow-200 font-medium text-midnight;
}

.rich-content code {
  @apply bg-link-water;
}

.rich-content blockquote {
  @apply border-l-2 border-geyser italic text-kasmir pl-2 my-2;
}

.rich-content ul {
  @apply list-disc ml-6 pr-2 my-2;
}

.rich-content li {
  @apply ml-1;
}

.rich-content ul li {
  @apply list-disc my-1;
}

.rich-content ol {
  @apply list-decimal ml-6 pr-2 my-2;
}

.rich-content ol li {
  @apply list-decimal my-1;
}

.rich-content h1 {
  @apply font-bold text-xl;
}

.rich-content h2 {
  @apply font-bold text-lg;
}

.rich-content table {
  @apply w-full;
}

.rich-content table tbody tr:first-child td {
  @apply border-t-0;
}

.rich-content table tbody tr td {
  @apply break-all w-1/3 align-top;
}

.rich-content:not(.rich-editor) div > *:after {
  @apply inline-block;
  content: "";
}
.rich-content.rich-editor > p {
  text-wrap: balance;
}
.uil-times:before {
  @apply block w-4 h-4 text-kasmir opacity-80;
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='currentColor' d='M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z'/%3E%3C/svg%3E");
}

[class*="multiValue"]:nth-last-child(2) .multiselect-comma {
  display: none;
}

.stage-change-form-top {
  box-shadow: 0 -5px 5px -5px rgba(0, 112, 210, 0.35), 5px 0 5px -5px rgba(0, 112, 210, 0.35), -5px 0 5px -5px rgba(0, 112, 210, 0.3);
}

.stage-change-form-middle {
  box-shadow: 5px -10px 5px -5px rgba(0, 112, 210, 0.35), -5px -10px 5px -5px rgba(0, 112, 210, 0.3);
}

.stage-change-form-bottom {
  box-shadow: 0 5px 5px -5px rgba(0, 112, 210, 0.35), 5px 0 5px -5px rgba(0, 112, 210, 0.35), -5px 0 5px -5px rgba(0, 112, 210, 0.3);
}

.talent-project-aa-form {
  @apply bg-white text-sm;
  height: 40px;
  font-family: 'Lato'
}

.talent-project-aa-root {
  font-family: 'Lato'
}

.globalsearch-aa-root {
  font-family: 'Lato'
}

input[disabled] { pointer-events:none }

.date-picker-w-full {
  width: 100%!important;
}

@media (min-width: 640px) {
  .tooltip{
    position:relative;
  }
  
  .tooltip:hover{
    text-decoration:none;
  }
  
  .tooltip:after{
    content: attr(tip);
    font-family:Lato, Helvetica, sans-serif;
    font-weight: 500;
    font-size: 13px;
    line-height:1.2em;
    color:#101937;
    min-width: 325px;
    padding:5px;
    border-radius:5px;
    background:#fff;
    position:absolute;
    top:30px;
    left:-10px;
    display:none;
    box-shadow: 1px 1px 10px #a8b7c7;
  }
  .tooltip:before{
    z-index:1000;
    position:absolute;
    content:"";
    top:15px;
    left:0px;
    border-right:7px transparent solid;
    border-left:7px transparent solid;
    display:none;
  }
  .tooltip:hover{
    z-index:1000;
    position:relative;
    color:#8325f7;
  }
  .tooltip:hover:after{
    display:block;
  }
  .tooltip:hover:before{
    display:block;
  }
}

.about-me p {
  margin: 8px 0;
}
.about-me ul {
    list-style-type: disc; 
    margin-left: 20px; 
}
.about-me ol {
  list-style-type: decimal; 
  margin-left: 20px; 
}

