/* Lato Semibold */
@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    src: url('./Lato-SemiBold.ttf') format('truetype');
    font-display: swap;
}

/* Lato Semibold Italic */
@font-face {
    font-family: 'Lato';
    font-style: italic;
    font-weight: 600;
    src: url('./Lato-SemiBoldItalic.ttf') format('truetype');
    font-display: swap;
}

/* Google doesn't make the 600 weight available directly. */